<template>
  <div class="info" v-loading="loading">
    <div style="padding-bottom: 30px">
        <img src="../assets/feiyi.jpg" style="width:100%"/>
      </div>
    <div v-if="!isShowInfo">
      <div style="padding-bottom:30px"><h1>证书查询系统</h1></div>
      <div class="teacherForm">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm">
          <el-form-item label="考生姓名" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入考生姓名"></el-input>
          </el-form-item>
          <el-form-item label="身份证号码" prop="idNo">
            <el-input v-model="ruleForm.idNo" placeholder="请输入身份证号"></el-input>
          </el-form-item>
          <el-form-item label="项目" prop="item">
            <el-input v-model="ruleForm.item" placeholder="书法|硬笔书法|软笔书法|国画花鸟|国画山水|儿童画"></el-input>
          </el-form-item>
          <el-button type="primary" style="width:80%;margin: 20px 0;" @click="submitForm('ruleForm')">查询</el-button>
        </el-form>
      </div>
    </div>
    <div v-else style="margin：10%;position: relative;">
      <h1>查询结果</h1>
      <el-card class="box-card" style="width:90%;margin:10% auto;position: relative;">
        <!-- <div class="li_box" style="" v-if="bookInfo.serialNumber != null"><div class="li_left">序号</div><div class="li_right"> {{bookInfo.serialNumber}}</div></div> -->
        <div class="li_box" v-if="bookInfo.name != null"><div class="li_left">考生姓名</div><div class="li_right"> {{bookInfo.name}}</div></div>
        <div class="li_box" v-if="bookInfo.gender != null"><div class="li_left">性别</div><div class="li_right"> {{bookInfo.gender}}</div></div>
        <div class="li_box" v-if="bookInfo.birthDate != null"><div class="li_left">出生年月</div><div class="li_right"> {{bookInfo.birthDate}}</div></div>
        <div class="li_box" v-if="bookInfo.idNo != null"><div class="li_left">身份证号</div><div class="li_right"> {{bookInfo.idNo}}</div></div>
        <div class="li_box" v-if="bookInfo.item != null"><div class="li_left">项目</div><div class="li_right"> {{bookInfo.item}}</div></div>
        <div class="li_box" v-if="bookInfo.level != null"><div class="li_left">级别</div><div class="li_right"> {{bookInfo.level}}</div></div>
        <div class="li_box" v-if="bookInfo.evaluate != null"><div class="li_left">综合评价</div><div class="li_right"> {{bookInfo.evaluate}}</div></div>
      </el-card>
      <el-button type="primary" style="width:80%;margin: 0px 0;" @click="tocheck">返回</el-button>
    </div>
  </div>
</template>

<script>
import bookApi from '../api/book.js'

export default {
  data() {
    const checkIdNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('身份证不能为空'))
      }
      var pass = false
      if(value.length == 18){
        value = value.split('');
        var factor = [ 7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2 ];
        var parity = [ 1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2 ];
        var sum = 0;
        var ai = 0;
        var wi = 0;
        for (var i = 0; i < 17; i++)
        {
          ai = value[i];
          wi = factor[i];
          sum += ai * wi;
        }
        var last = parity[sum % 11];
        if(last == value[17]){
          pass = true
        }
      }
      if (!pass) {
        callback(new Error('请输入正确的身份证号码'))
      } else {
        callback()
      }
    }
    return {
      isShowInfo: false,
      loading: false,
      ruleForm: {
        name: '',
        idNo: '',
        item: ''
      },
      bookInfo:{
        serialNumber:"",
        name:"",
        gender:"",
        birthDate:"",
        idNo:"",
        item:"",
        level:"",
        evaluate:"",
      },
      file:{},
      rules: {
        name: [
          { required: true, message: '请输入考生姓名', trigger: 'change' }
        ],
        idNo: [
          { required: true, message: '请输入身份证号', trigger: 'change' },
          { validator: checkIdNum, trigger: 'change' }
        ],
        item: [
          { required: true, message: '请输入专业', trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    tocheck(){
      this.isShowInfo = false
      this.ruleForm = {name: '',idNo: '',item: ''}
    },
    submitForm(ruleForm) {
      this.$refs[ruleForm].validate((valid) => {
        if (valid) {
          this.checkBook()
        }
      });
    },
    checkBook() {
      var that = this
      that.loading = true
      bookApi.checkBook(this.ruleForm).then(res => {       
        that.loading = false 
        if(res.data.success){
          that.isShowInfo = true
          that.bookInfo = res.data.data.info
        } else {
          this.$message.error(res.data.message);
        }
      }).catch(function (error) {})
    },
  },
}
</script>

<style scoped>
.info {
  width: 100%;
  margin: 0 auto;
}
.teacherForm {
  margin: 0 auto;
  padding: 10px 20px;
}
.teacherForm .el-form-item{
  text-align: left;
  float: none;
  margin-bottom: 10px;
}
.teacherForm >>> .el-form-item__label {
  float: none;
}
.li_box{
  width: 100%;
  float: none;
  position: relative;
  height: 50px;
  line-height: 48px;
  border-bottom: 1px solid #dddddd;
}
.li_box div{
  display: inline-block;
  height: 50px;
  font-size: 14px;
  line-height: 48px;
}
.li_left{
  width: 40%;
  left: 0px;
  text-align: left;
  color: #999999;
   height: 50px;
  line-height: 48px;
}
.li_right{
  width: 60%;
  right: 0px;
   height: 50px;
  text-align: right;
  color: 333333;
  line-height: 48px;
}
</style>>